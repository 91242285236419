<svelte:options tag="fds-context-menu"/>

<script>
    import {onMount, afterUpdate} from "svelte";
    import MenuEntry from "./menu_entry.svelte";
    import {get_current_component} from "svelte/internal";
    import fdsHelper from "@fds-components/fds-helper"
    let path = fdsHelper.get_href()
    import { version,name } from '../package.json';
    /**
     * @typedef {{type: string, href: string, target: string,icon:string,[items:{key:ContextMenuData}]}} ContextMenuData
     */


    let demoMenu = {
        fds: {
            type: "link",
            href: "https://flyingdog.de",
            target: "_blank",
            name: "flying dog software",
        },
        submenu: {
            name: "Select...",
            items: {
                submenu1: {name: "Submenu 1"},
                submenu2: {name: "Submenu 2"},
                submenu3: {
                    name: "Submenu 3",
                    items: {
                        subsubmenu1: {href: "#", name: "SubSubmenu 1"},
                        subsubmenu2: {href: "#", name: "SubSubmenu 2"},
                        subsubmenu3: {href: "#", name: "SubSubmenu 3"},
                    },
                },
            },
        },
        sep1: "---------",
        dogmenu: {
            icon: "fas dog",
            name: "Dog menu...",
            items: {
                dogmenu1: {name: "Bone", icon: "fas bone"},
                dogmenu2: {name: "Paw", icon: "fas paw"},
                dogmenu3: {name: "Hot Dog", icon: "fas hotdog"},
            },
        },
    };
    /**
     * Menu data structure - see below
     * @type {ContextMenuData}
     */
    export let menu = {};
    /**
     * Activates demo mode with "true" with some demo menu entries
     * @type {boolean}
     */
    export let demo = false;
    /**
     * x position of context menu
     * @type {number}
     */
    export let x = 0;
    /**
     * y position of context menu
     * @type {number}
     */
    export let y = 0;
    /**
     * Dom Element which activate context menu on click(context menu is attached to this element)
     * @type {HTMLElement }
     */
    export let element = null;
    /**
     * Global callback which will be called after one menu entry is selected.
     * @type {function}
     */
    export let callback = null;
    let host = get_current_component();
    /**
     * Determine if content menu appear on left side of click
     * @type {boolean}
     */
    export let open_left = false;
    /**
     * Determine if content menu appear on top of click
     * @type {boolean}
     */
    export let open_top = false;
    /**
     * Darkens whole background so it brings menu more on focus
     * @type {boolean}
     */
    export let darkBackground = false;
    /**
     * zindex property of menu
     * @type {number}
     */
    export let zindex = 2000;
    /**
     * If menu is grid - like icon menu, or color picker
     * @type {boolean}
     */
    export let grid = false;
    /**
     * grid colums in grid mode
     * @type {number}
     */
    export let gridcols = 5;
    /**
     * If menu is grid - like icon menu, or color picker, value should be the same as grid
     * @type {boolean}
     */
    export let griddisplay=false;
    /**
     *  width of grid element if menu or submenu is grid type
     * @type {string}
     */
    export let gridwidth = "";

        let columnpercent = "20%";
    let subOpenLeft = "false";
    let subOpenTop = "false";
    let visibility = "hidden";
    let alreadyCalculated = false;
    let gridwidthelementstyle = "";

    $: if(gridcols!=5) columnpercent = Math.floor(100/gridcols)+"%";
    $: if(gridwidth)  gridwidthelementstyle = gridwidth;
    $: {if(open_left=='false') open_left=false; open_left=!!open_left;}
    $: {if(open_top=='false') open_top=false; open_top=!!open_top;}
    $: {if(griddisplay=='false') griddisplay=false; griddisplay=!!griddisplay;}
    $: {if(grid=='false') grid=false; grid=!!grid;}
    $: {if(darkBackground=='false') darkBackground=false; darkBackground=!!darkBackground;}


    /**
     * Get information about component
     * @param  {("api" | "examples" | "css")} type the info type
     */
    export async function getInfo(type) {
        if (type==="version") return version
        let res = await fdsHelper.getInfo(type,name);
        return res;
    }


    afterUpdate(() => {
        if (visibility === "visible") return;
        if (alreadyCalculated) return;
        alreadyCalculated = true;
        let inSubMenu = false;
        if (!menu) return;
        let rect;
        let div = host.shadowRoot.getElementById("menu");
        if (!div) return;
        setTimeout(() => {
            // workaround because otherwise width is still not updated /correct
            rect = div.getBoundingClientRect(); // correct ones now
            let parent = host.parentNode;
            if (parent && parent.className === "contextMenuEntry")
                inSubMenu = true;
            if (!inSubMenu) {
                x = Math.min(window.innerWidth - (rect.width + 10), x); // top menu
                y = Math.min(window.innerHeight - (rect.height + 10), y);
                if (!darkBackground) {
                    // otherwise parent position fixed
                    x = x + parseInt(window.scrollX);
                    y = y + parseInt(window.scrollY);
                }
            } else {
                // sub menu might open left to parent menu
                let rectParent = parent.getBoundingClientRect();
                if (
                    rect.x > window.innerWidth - (rect.width + 10) ||
                    open_left == true
                ) {
                    x = x - rectParent.width - rect.width;
                    subOpenLeft = "true"; // if once open left all child sub menus have to open left as well
                }
                if (
                    rect.y > window.innerHeight - (rect.height + 10) ||
                    open_top === true
                ) {
                    let delta =
                        rect.y - (window.innerHeight - (rect.height + 10));
                    y = y - delta;
                    subOpenTop = "true"; // if once open top all child sub menus have to open top as well
                }
            }

            visibility = "visible"; // after all calculations show menu without flicker
        }, 0);
    });

    function activateDarkBackground() {
        let back;
        back = document.getElementById("fds_context_back");
        if (back) back.remove();
        back = document.createElement("div");
        back.style =
            "position: fixed; left:0; top: 0;width: 100%;height: 100vh; background-color: black; opacity: 50%;z-index:1000";
        back.id = "fds_context_back";
        document.body.append(back);
    }

    function removeDarkBackground() {
        let back = document.getElementById("fds_context_back");
        if (back) back.remove();
    }

    onMount(() => {

        let rectParent, rect;
        let inSub = false;
        if (demo) menu = demoMenu;
        if(griddisplay) gridwidthelementstyle = gridwidth || "width:200%";
        if (element) {
            rect = element.getBoundingClientRect();
            x = rect.x;
            y = rect.y;
        }
        let parent = host.parentNode;
        let otherTag = document.getElementsByTagName("fds-context-menu")[0];
        // set default position without any restrictions, have to be corrected after first render
        if (parent.className === "contextMenuEntry") {
            rectParent = parent.getBoundingClientRect();
            x = rectParent.width;
            y = 0;
            inSub = true;
        } else if (otherTag && otherTag !== host) {
            otherTag.remove();
        }
        if (!inSub && darkBackground) {
            activateDarkBackground();
            host.style = "z-index: 1010;position:fixed;left:0;top:0";
        }
        document.body.addEventListener("click", clickBody);
    });
    let showSub = {};

    function enter(entry, id) {
        if (!entry.items) return;
        showSub[id] = true;
    }

    function leave(entry, id) {
        if (!entry.items) return;
        showSub[id] = false;
    }

    function clickBody(e) {
        if (visibility === "hidden") return;
        removeDarkBackground();

        document.body.removeEventListener("click", clickBody);
        let parent = host.parentNode;
        if (parent) parent.removeChild(host);
        e.preventDefault();
        return false;
    }

    function handleClick(cb,entry) {
        if (cb) cb();
        if (callback) callback(entry.name, "", entry);
    }

</script>

<div
        class="menu"
        style="left:{x}px;top:{y}px;visibility:{visibility};z-index:{zindex}; {gridwidthelementstyle}"
        id="menu">


    {#if grid}
        <ul class="picker-list">
            {#each Object.entries(menu) as [id, entry]}
            <li style="flex: 0 0 {columnpercent};"><a on:click={() => handleClick(entry.callback,entry)}>
                <fds-icon
                        name={entry.icon}
                        style="--color:{entry.iconcolor}"
                        class="icon"
                        height="15"
                />
            </a></li>
            {/each}
        </ul>
    {/if}

    {#each Object.entries(menu) as [id, entry]}
        {#if !grid}
            {#if entry === "---------"}
                <hr/>
            {:else}
                <div
                        on:mouseenter={() => enter(entry, id)}
                        on:mouseleave={() => leave(entry, id)}
                        class="contextMenuEntry">
                    <MenuEntry {entry} {id} {callback}/>
                    {#if entry.items && showSub[id]}
                        <fds-context-menu
                                menu={entry.items}
                                open_left={subOpenLeft}
                                open_top={subOpenTop}
                                grid={entry.grid}
                                griddisplay={entry.grid}
                                {callback}/>
                    {/if}
                </div>
            {/if}
        {/if}
    {/each}


    <div class="active entry  right image submenu" style="display:none">
        <img src="" class="icon" alt=""/>
    </div>
</div>

<style>
    * {
        box-sizing: border-box;
    }

    .picker-list li {
        display: flex;
       /* flex: 0 0 20%;*/
        float: left;
        width: 20%;
    }

    .picker-list {
        display: flex;
        flex-flow: row wrap;
        list-style: none;
        padding-left: 0;
    }

    .picker-list a {
        display: block;
        flex: 1 1 auto;
        line-height: 1.4;
        padding: 5px;
        text-align: center;
        user-select: none;
    }

    .picker-list a:hover, .picker-list a.active {
        background-color: #f1f1f1;
        color: #fff;
        cursor: pointer;
        text-decoration: none;
    }

    hr {
        width: 100%;
    }

    .menu {
        position: absolute;
        display: grid;
        border: 1px solid #0003;
        box-shadow: 2px 2px 5px 0px #0002;
        padding: 5px;
        border-radius: 5px;
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter: blur(4px);
        background: var(--bgColor, rgba(255, 255, 255, 0.6));
    }

    .contextMenuEntry {
        width: 100%;
        position: relative;
    }

    .entry {
        white-space: nowrap;
        float: left;
        display: block;
        color: var(--color, rgba(0, 0, 0, 0.87));
        text-align: left;
        padding-left: 10px;
        padding-right: 10px;

        margin-bottom: 5px;
        text-decoration: none;
        font-size: 14px;
        padding-top: 5px;
        padding-bottom: 5px;
        cursor: pointer;
        width: 100%;
    }

    .right {
        float: right;
    }

    .submenu::after {
        content: "";
        border-style: solid;
        border-width: 0.25em 0 0.25em 0.25em;
        border-color: transparent transparent transparent #2f2f2f;
        height: 0;
        position: absolute;
        right: 2px;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 0;
        z-index: 1;
    }

    .entry:hover .icon {
        --color: var(--activeColor, white);
    }

    .entry:hover {
        background-color: var(--activeBgColor, #337ab7);
        color: var(--activeColor, white);
    }

    .icon {
        vertical-align: text-bottom;
        height: 15px;
        display: inline-block;
        margin-right: 5px;
    }

    .image {
        padding: 0;
        line-height: 45px;
        vertical-align: middle;
    }

    .image img {
        vertical-align: middle;
        max-height: 35px;
    }
</style>
