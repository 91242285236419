<script>
	import { onMount } from "svelte";

	export let entry = {};
	export let id = "";
	export let callback = null;
	let className = "entry ";
	function handleClick(cb) {
		if (cb) cb();
		if (callback) callback(entry.name, "", entry);
	}
	function setClassName() {
		entry.id = id;
		if (!entry.type) entry.type = "text";
		if (entry.active) className += " active";
	}
	$: if (entry) setClassName();
	onMount(() => {
		setClassName();
	});
</script>

{#if !entry.hide}
	{#if entry.type === "link"}
		<a href={entry.href} target={entry.target} class={className}>
			{#if entry.icon}<fds-icon
					name={entry.icon}
					class="icon"
					height="15"
				/>{/if}
			{#if entry.name}{@html entry.name}{/if}
			{#if entry.image}<img alt={entry.name} src={entry.image} />{/if}
		</a>
	{/if}
	{#if entry.type === "text" && !entry.items}
		<span class={className} on:click={() => handleClick(entry.callback)}>
			{#if entry.icon}<fds-icon
					name={entry.icon}
					class="icon"
					height="15"
				/>{/if}
			{#if entry.name}{@html entry.name}{/if}
			{#if entry.image}<img alt={entry.name} src={entry.image} />{/if}
		</span>
	{/if}
	{#if entry.items}
		<span class="{className} submenu">
			{#if entry.icon}<fds-icon
					name={entry.icon}
					class="icon"
					height="15"
				/>{/if}
			{#if entry.name}{@html entry.name}{/if}
			{#if entry.image}<img alt={entry.name} src={entry.image} />{/if}
		</span>
	{/if}
{/if}

<style>
</style>
